.valentines-day {
  h1,
  h2 {
    color: var(--bs-accent-2);
  }

  .font-handsome {
    font-family: 'handsome-pro', sans-serif;
  }
}


