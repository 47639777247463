//Order matters - refer to these: https://getbootstrap.com/docs/5.3/customize/optimize/#lean-sass-imports & https://getbootstrap.com/docs/5.3/customize/sass/#importing
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'bootstrap/scss/functions';
@import 'bootstrap-icons/font/bootstrap-icons';

// 2. Include any default variable overrides here
$enable-grid-classes: false;
$enable-cssgrid: true;
$font-family-base: 'proxima-nova', 'Helvetica', sans-serif;
$headings-font-family: 'playfair-display', serif;
$headings-font-weight: 700;
$primary: #242424;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/variables-dark';

// 4. Include any default map overrides here
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1440px
);

// Create your own map
$custom-colors: (
  'knack': #5fccc7,
  'accent-1': #ffb71b,
  'accent-2': #71174f,
);

$utilities: (
  'opacity': (
    property: opacity,
    class: opacity,
    state: hover,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    )
  )
);

// Merge the maps
//Disable stylelint for this section because it doesn't like map-merge and the suggestion to use map.merge doesn't work
/* stylelint-disable scss/no-global-function-names */
$theme-colors: map-merge($theme-colors, $custom-colors);
/* stylelint-enable scss/no-global-function-names */

// 5. Include remainder of required parts
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/root';

// Merge the maps
//Disable stylelint for this section because it doesn't like map-merge and the suggestion to use map.merge doesn't work
/* stylelint-disable scss/no-global-function-names */
$utilities: map-merge($utilities, (
  'position': map-merge(map-get($utilities, 'position'),
  (responsive: true),),
  'text-color': map-merge(map-get($utilities, 'color'),
  ( responsive: true),),
  'translate-middle': map-merge(map-get($utilities, 'translate-middle'),
  (responsive: true),),
  'width': map-merge(map-get($utilities, 'width'),
  ( responsive: true),),
));
/* stylelint-enable scss/no-global-function-names */

// 6. Optionally include any other parts as needed
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/button-group';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/card';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/breadcrumb';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/alert';
// @import "bootstrap/scss/progress";
@import 'bootstrap/scss/list-group';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/toasts';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
// @import "bootstrap/scss/carousel";
@import 'bootstrap/scss/spinners';
@import 'bootstrap/scss/offcanvas';
@import 'bootstrap/scss/placeholders';
@import 'bootstrap/scss/helpers';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import 'bootstrap/scss/utilities/api';

// 8. Add additional custom code here
@import '../stylesheets/megamenu';
@import '../stylesheets/pages';
@import '../stylesheets/personalization';
@import '../stylesheets/quick_search';
@import '../stylesheets/slider';

//Bootstrap 6 is supposed to make changing colors easier, but for now, we have to do it manually
//Disable stylelint for this section because it doesn't like the lighten function - and the suggestion to use color.adjust() doesn't work
/* stylelint-disable scss/no-global-function-names */
.btn-primary {
  --bs-btn-hover-bg: #{lighten($primary, 15%)};
  --bs-btn-hover-border-color: #{lighten($primary, 20%)};
}
/* stylelint-enable scss/no-global-function-names */

a {
  text-decoration: none;

  &:not(.btn):hover {
    text-decoration: underline;
  }
}

.turbo-progress-bar {
  height: 0.7rem;
  background-color: $primary;
}

// Set the height and width of Bootstrap icons so we can adjust the size of the icons using fs-1, fs-2, etc.
.bi {
  height: 1em;
  width: 1em;
}

.text-shadow {
  filter: drop-shadow(6.5px 6.5px 9.5px rgba(255, 255, 255, 0.5));
  text-shadow: -1px -1px 1px hsla(0, 0%, 100%, 0.1), 1px 1px 1px rgba(0, 0, 0, 0.5);
}
