swiper-container {
  --swiper-theme-color: #{$primary};
  --swiper-navigation-sides-offset: 0;
  --swiper-navigation-top-offset: -20px;

  &::part(button-prev) {
    left: auto;
    right: 50px;
  }

  &::part(button-prev),
  &::part(button-next) {
    background-color: $primary;
    border: 2px solid $primary;
    color: #fff;
    height: 20px;
    padding: 5px;
    width: 20px;

    &:hover {
      background-color: #fff;
      color: $primary;
    }
  }

  &::part(button-next) {
    padding-left: 7px;
  }

  &::part(button-prev) {
    padding-right: 7px;
  }
}

swiper-container::part(container) {
  overflow: visible;
  overflow-x: clip;
}

.swiper-slide-thumb-active {
  border-bottom: 4px solid $primary;
  padding-bottom: 1px;
}

swiper-container:not(:defined),
swiper-slide:not(:defined) {
  display: block;
  height: 100%;
  visibility: hidden;
  width: 100%;
}

.pdp-image-wrapper {
  height: 510px;
}

@include media-breakpoint-up(sm) {
  .pdp-image-wrapper {
    height: 700px;
  }
}
