@import 'stylesheets/shared/rem-calc';

.personalization-design-previewer,
.personalization-confirmation {
  .preview {
    position: relative;

    picture {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    svg {
      position: relative;
      z-index: 1;

      &[data-template-invert] {
        .preview-contents {
          transform: scale(-1, -1) !important;
        }
      }
    }
  }

  // This is what properly centers the personalized item photo
  .preview-photo {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.personalization-design-previewer {
  .designs {
    .design {
      &:hover {
        .preview-template { border-color: #999; }
      }

      &.design-selected {
        .preview-template { border-color: #f78700; }
      }

      .preview-template {
        border: rem-calc(1) solid #efefef;
        font-weight: normal;
        max-width: rem-calc(100);
        max-height: rem-calc(100);
        width: 100%;
      }
    }
  }
}
