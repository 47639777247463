.search-modal {
  --bs-modal-width: 100%;
  --bs-modal-border-width: 0;
  --bs-modal-padding: 0;

  .btn-close {
    right: 20%;
    top: 30px;
  }
}

.quick-search {
  height: 81px;

  .list-group-item {
    cursor: pointer;
  }

  input:focus {
    outline: none;
  }
}

@include media-breakpoint-down(md) {
  .search-modal .btn-close {
    right: rem-calc(10);
  }
}

@include media-breakpoint-up(md) {
  .quick-search {
    padding-left: 20%;
    padding-right: 20%;
  }
}
