@include media-breakpoint-down(lg) {
  .navbar li.dropdown .dropdown-menu {
    ul {
      --bs-columns: 1;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar li.dropdown {
    position: static;

    .dropdown-menu {
      box-shadow: 0 1rem 3rem rgba($black, 0.175);
      display: block;
      left: 0;
      top: 99%;
      opacity: 0;
      transition: 0.3s;
      visibility: hidden;
      width: 100%;

      ul {
        --bs-columns: 5;
      }
    }

    &:hover .dropdown-menu {
      opacity: 1;
      transform: rotateX(0deg);
      transition: 0.3s;
      visibility: visible;
    }
  }
}
